.player-cont {
  margin: 40px 0 auto;
  justify-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.video-responsive {
  overflow: hidden;
  padding-top: 40.25%;

  position: relative;
  height: 0;
  margin: 10px !important;
  width: 100%;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 70%;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 600px) {
  .video-responsive iframe {
    height: 100%;
  }
  .video-responsive {
    overflow: hidden;
    padding-top: 60.25%;

    position: relative;
    height: 0;
    margin: 10px !important;
    width: 100%;
  }
}
