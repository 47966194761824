.header1 {
  font-family: "Poppins", serif;
  text-align: right;
  font-weight: 400;
  border-bottom: 1px solid var(--main-color);
  display: flex;
  padding-bottom: 5px;
}

.header-item {
  font-size: 30px;
  color: rgb(172, 172, 172);
  padding-right: 10px;
  margin-top: 10px !important;
  border-right: 1px solid #fff;
  z-index: 100;
}
.header-item a {
  color: rgb(99, 99, 99);
}
.header-item a:hover {
  color: var(--main-color);
}
.header-cont {
}
.header_right {
  display: none;
}
.shop-icon {
  margin-left: 20px !important;
  margin-top: 15px !important;
  width: 50px !important;
  z-index: 100;
}
