.slider-cont {
  height: 70vh;
  margin: 0 !important;
}
.carousel-item {
}

.carousel-item .d-block {
  height: 70vh !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.carousel-indicators {
  display: none !important;
}
.c-caption {
  margin-bottom: 20px !important;
}
.c-caption a {
  text-decoration: none;
}
.c-head {
  color: rgb(114, 116, 113);
  padding: 12px;
  font-weight: 600 !important;
  text-shadow: 1px 1px #fff;
}
.c-text {
  padding-bottom: 20px;
}
@media only screen and (max-width: 600px) {
  .c-head,
  .c-text {
  }
}
