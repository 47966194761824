.custom_btn {
  background-color: green !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  border-radius: 50px !important;
  padding: 2px 16px !important;
  line-height: 35px !important;
  min-width: 100px !important;
  outline: none !important;
  z-index: 1;
  overflow: hidden;
  transition: color 300ms ease-in-out;
}

.custom_btn .btn_icon_container {
  color: #fff !important;
  background: var(--main-color) !important;
  border-radius: 50px !important;
  height: 33px !important;
  width: 30px !important;
  line-height: 29px !important;
  display: flex !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
}
.custom_btn .btn_icon_container > .MuiSvgIcon-root {
  font-size: 16px !important;
}
.custom_btn .btn_text {
  font-size: 20px !important;
  text-transform: none !important;
  text-align: center;
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
  color: var(--main-color);
  font-weight: 570;
}
.header-item a {
  text-decoration: none;
  color: #fff;
}

.custom_btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: -1;
  border-radius: 50px !important;
  transform: scale(1.5);
  transition: transform 500ms ease-in-out;
}
.custom_btn:hover::before,
.custom_btn:focus::before {
  transform: scale(0);
}
.btn_text:hover,
.btn_text:focus {
  color: #fff;
}
