.service-head {
  text-align: center;
  margin-top: 40px !important;
}
.card-item {
}
.service-card {
  width: 400px !important;
}
.grid-cont {
  margin: 20px 20px !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  width: 90% !important;
  justify-content: left;
  margin: 0 auto !important;
}
.MuiPaper-root > .service-card1 {
  height: 300px !important;
}
