.eachcard {
  width: 500px !important;
  margin-top: 100px;
}
.Gallery-cont {
  margin: 40px 0 40px 15px;
}

/* Tabs*/

.MuiTabs-flexContainer {
  flex-wrap: wrap !important;
}
.customTabs {
  flex-wrap: wrap !important;
}
.customTabs .customTabs_item {
  padding: 0px !important;
  min-width: 0px !important;
  margin-right: 20px !important;
  text-transform: inherit !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  outline-width: 0px !important;
}
.customTabs .active {
  color: black !important;
  background: var(--bg-color);
}
/* End Tabs style*/
/*Cards style*/
.image-cont {
  margin-top: 40px;
}
.customCard {
  height: 100%;

  box-shadow: 0px 0px 48px 0px rgba(4, 6, 4, 0.08) !important;
  border-radius: 5px;
  overflow: hidden;
  outline-width: 0px !important;
  margin-right: 15px;
}

.customCard .customCard_image {
  height: 300px;
}
.customCard .customCard_title {
  font-weight: 500;
}
.customCard .customCard_caption {
  color: gray !important;
  font-weight: 400;
}
/*End of Cards style*/

/*Dialogue styles*/
.projectDialog {
}
.projectDialog .projectDialog_image {
  height: 400px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.projectDialog .projectDialog_description {
  margin-top: 10px !important;
}
.projectDialog_actions {
  justify-content: center !important;
  margin-bottom: 10px;
}
.projectDialog .projectDialog_icon {
  color: darkslategray;
  margin: 0px 12px;
  cursor: pointer;
}
.projectDialog .projectDialog_icon :hover {
  color: var(--main-color);
}
.projectDialog .projectDialog_icon > .MuiSvgIcon-root {
  font-size: 30px !important;
}
/*Dialogue styles End*/
