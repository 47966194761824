@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
