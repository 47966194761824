.projbox {
}
.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh !important;
  background-size: cover;
  background-position: center;
}

.service-intro {
  width: 60vw !important;
  text-align: center;
  margin: 50px auto;
}
/* Tabs*/

.customTab .customTab_item {
  padding: 0px !important;
  min-width: 0px !important;
  margin-right: 20px !important;
  text-transform: inherit !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  outline-width: 0px !important;
  margin: 20px auto !important;
  color: black !important;
  padding: 50px !important;
  border: 1px solid var(--main-color) !important;
  transition: 0.5s;
}
.customTab .customTab_item:hover {
  background-color: rgb(172, 231, 172);
}
.service-wrapper {
  margin: 0 auto !important;
}
.customTabs .active {
  color: var(--main-color) !important;
}
.service-link a {
  margin-top: 25px !important;
  color: var(--main-color);
}
/* End Tabs style*/
/*Cards style*/
.customCard {
  width: 90% !important;
  box-shadow: 0px 0px 48px 0px rgba(4, 6, 4, 0.08) !important;
  border-radius: 5px;
  overflow: hidden;
  outline-width: 0px !important;
  margin-right: 15px;
  margin: 0 auto !important;
  margin-top: 40px !important;
}

.customCard .customCard_image {
  height: 60vh !important;
}
.customCard .customCard_title {
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px !important ;
}
.customCard .customCard_caption {
  color: black !important;
  font-weight: 400;
}
.customCard_caption {
  font-size: 16px !important;
  text-align: center;
  margin: 10px 0 !important;
}
.subservice-head {
  font-size: 19px !important;
  text-align: center;
  margin: 10px 0 !important;
  color: var(--main-color);
  font-weight: 550 !important;
}
/*End of Cards style*/

/*Dialogue styles*/
.projectDialog {
}
.projectDialog .projectDialog_image {
  height: 400px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.projectDialog .projectDialog_description {
  margin-top: 10px !important;
}
.projectDialog_actions {
  justify-content: center !important;
  margin-bottom: 10px;
}
.projectDialog .projectDialog_icon {
  color: darkslategray;
  margin: 0px 12px;
  cursor: pointer;
}
.projectDialog .projectDialog_icon :hover {
  color: var(--main-color);
}
.projectDialog .projectDialog_icon > .MuiSvgIcon-root {
  font-size: 30px !important;
}
/*Dialogue styles End*/
