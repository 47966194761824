.nav-home {
  height: 100%;
}
.nav-home img {
  height: 50% !important;
  width: 80px;
}
.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.header2 {
  margin: 0 !important;
  padding: 0 !important;
}
.header {
  height: 90px;
  z-index: 100;
  background-color: #fff !important;
}

.header .header_navlink {
  padding: 0;
}

.header .header_link {
  font-weight: 500;
  color: rgb(112, 111, 111) !important;
  font-size: 17px !important;
}

.header .header_link:hover {
  color: var(--main-color) !important;
}
.header .header_link_active {
  font-weight: 500;
  color: var(--main-color) !important;
}

.header .header_home {
  background-color: var(--main-color);
  padding: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.header .header_left {
  margin-right: auto;
}
.header .header_right {
  display: flex !important;
  align-items: center;
}
.header .header_right > a > .MuiSvgIcon-root {
  font-size: 30px;
  margin-right: 12px;
  color: rgb(112, 111, 111) !important;
}
.header .header_right > a > .MuiSvgIcon-root:hover {
  color: var(--main-color) !important;
}
.drop-down {
  background-color: #fff;
}
nav .shop-link {
  font-weight: 500;
  color: rgb(112, 111, 111) !important;
  margin-right: 15px !important;
  margin-top: 7px;
  padding-bottom: 10px;
  text-decoration: none;
  font-size: 17px;
}

/*About Us dropdown style*/
.about-dropdown {
  font-size: 17px !important;
  font-weight: 540 !important;
  color: rgb(112, 111, 111) !important;
}
.header #basic-nav-dropdown:hover {
  color: var(--main-color) !important;
}
#drop-item:hover {
  background: rgb(93, 223, 104) !important;
}
#drop-item {
  background-color: #fff !important;
  font-size: 18px !important;
}
