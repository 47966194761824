/* Testimonial */
#testimonial {
}
#testimonial::before {
  opacity: 0.7;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.testimonial_area {
  position: relative;
}
#testimonial h2 {
  margin-top: 63px;
}
.testimonial_item {
  padding: 0 15px;
}
.testimonial_content {
  background: #e5e5e5 none repeat scroll 0 0;
  border-radius: 18px;
  margin: 55px 0 30px;
  padding: 40px 15px 40px 70px;
  text-align: left;
  position: relative;
  color: #393939;
}
.testimonial_content::after {
  background: inherit;
  bottom: -10px;
  content: "";
  height: 20px;
  left: 47%;
  position: absolute;
  transform: rotate(45deg);
  width: 20px;
}
.testimonial_content p {
  position: relative;
}
.testimonial_content p:first-child::before {
  color: #989898;
  content: ",,";
  font-family: "GillSansMT-UltraBold";
  font-size: 60px;
  left: -54px;
  position: absolute;
  top: -24px;
  transform: rotateY(180deg);
}
#testimonial .col-sm-4:nth-child(3n + 2) .testimonial_content {
  margin: 40px 0;
  padding-bottom: 70px;
}
.testimonial_item:hover .testimonial_content {
  background: var(--main-color) none repeat scroll 0 0;
}
.testimonial_item:hover .testimonial_content::after {
  width: 22px;
  height: 22px;
  bottom: -11px;
}
.testimonial_item:hover .testimonial_content {
  color: #fff;
}
.testimonial_item:hover .testimonial_content p:first-child::before {
  color: inherit;
}
.testimonial_item > img {
  border-radius: 100%;
  height: 80px;
  width: 80px;
}
.worker_name {
  font-family: "futura_ltbold", sans-serif;
  margin: 10px 0 45px;
  text-transform: uppercase;
}
