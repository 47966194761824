.jobBody{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    
    text-align: center;
}
.pageTitle{
    color: rgb(9, 143, 9);
    padding: 1em;
}
.joinUs{
  color: rgb(112, 111, 111); 
  padding: 1em; 
}

.jobText{
   width: 70%;
   margin-left: 14% !important;
  text-align: center;
}
img{
    display: inline-block;
}
.jobLink {
    padding: 1.5em;
    
    
}
.jobLink a{
    text-decoration: none;
    padding: 1.5em;
    color: rgb(9, 143, 9);
}