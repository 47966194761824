* {
  box-sizing: border-box;
  margin: 0;
}
:root {
  --main-color: green;
  --bg-color: rgb(240, 247, 240);
}
html {
  font-family: "Merriweather Sans", sans-serif;
  line-height: 1.5;
  color: #545454;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 70%;
  }
}
