.team {
  margin: 40px auto !important;
}
.row-cont {
  justify-content: center;
}
.profile {
  margin-top: 25px;
}
.profile .img-box {
  opacity: 1;
  display: block;
  position: relative;
}
.profile h2 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 15px;
}
.profile h3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
}
.img-box:after {
  content: "";
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}
.img-box ul {
  margin: 0;
  padding: 20px 0;
  position: absolute;
  z-index: 1;
  bottom: 0;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}
.img-box ul li {
  width: 50px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin: 3px;
  padding: 7px;
  display: inline-block;
}
.img-box a {
  font-size: 30px;
  color: #fff;
}
.img-box:hover:after {
  opacity: 1;
}
.img-box:hover ul {
  opacity: 1;
}

.img-box a:hover li {
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.img-box:after,
.img-box ul,
.img-box ul li {
  transition: all 0.5s ease-in-out 0s;
}
